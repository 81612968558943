const BASE_URL = "https://api.ermc.africa/integration-gateway/external";

class LocationSDK {
  /**
   * Gets the user's current location.
   * @returns {Promise<Object>}
   */
  static getLocation() {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        return reject(
          new Error("Geolocation is not supported by this browser.")
        );
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude, accuracy, altitude, speed } =
            position.coords;
          resolve({
            latitude,
            longitude,
            accuracy,
            altitude,
            speed,
            timestamp: position.timestamp,
          });
        },
        (error) => {
          reject(new Error(error.message));
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    });
  }

  /**
   * Attaches location data to the provided event details.
   * @param {Object} eventDetails - The original event details.
   * @returns {Promise<Object>} - The updated event details with location data.
   */
  static async attachLocation(eventDetails) {
    try {
      const location = await this.getLocation();
      return {
        ...eventDetails,
        location,
      };
    } catch (error) {
      throw new Error(`Failed to get location: ${error.message}`);
    }
  }

  /**
   * Sends a request to the specified URL with the provided headers and data.
   * @param {string} url - The endpoint URL.
   * @param {Object} headers - The request headers.
   * @param {Object} data - The request data.
   * @returns {Promise<Object>} - The response data.
   */
  static async sendEvent(url, headers, data) {
    try {
      const updatedData = await this.attachLocation(data);

      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        throw new Error(`Failed to send event: ${response.statusText}`);
      }

      return await response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**
   * Reports a bet event.
   * @param {string} apiKey - The API key.
   * @param {Object} eventDetails - The event details.
   * @param {string} eventDetails.bet_id - The bet ID.
   * @param {string} eventDetails.user_id - The user ID.
   * @param {number} eventDetails.stake_amount - The stake amount.
   * @param {number} eventDetails.odds - The odds.
   * @param {number} eventDetails.expected_payout - The expected payout.
   * @param {Object} eventDetails.metadata - Additional metadata.
   * @returns {Promise<Object>} - The response data.
   */
  static reportBetEvent(apiKey, eventDetails) {
    const url = `${BASE_URL}/report-bet-event`;
    const headers = {
      "x-api-key": apiKey,
      "Content-Type": "application/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIyYjNiMmUxMi00ZTg5LTQyN2MtYjQ1MC1kZmFjYzcxNmMyNmMiLCJpYXQiOjE3Mzg3MzcyNTAsImV4cCI6MTczODczNzg1MH0.bODTjs8KqCFLWr81Ypgw53m-UMOdKtsgxdIjLh1tPfg",
    };
    return this.sendEvent(url, headers, eventDetails);
  }

  /**
   * Reports a withdrawal event.
   * @param {string} apiKey - The API key.
   * @param {Object} eventDetails - The event details.
   * @param {string} eventDetails.user_id - The user ID.
   * @param {number} eventDetails.amount - The amount.
   * @param {string} eventDetails.request_id - The request ID.
   * @param {Object} eventDetails.metadata - Additional metadata.
   * @returns {Promise<Object>} - The response data.
   */
  static reportWithdrawalEvent(apiKey, eventDetails) {
    const url = `${BASE_URL}/report-withdrawal`;
    const headers = {
      "x-api-key": apiKey,
      "Content-Type": "application/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIyYjNiMmUxMi00ZTg5LTQyN2MtYjQ1MC1kZmFjYzcxNmMyNmMiLCJpYXQiOjE3Mzg3MzcyNTAsImV4cCI6MTczODczNzg1MH0.bODTjs8KqCFLWr81Ypgw53m-UMOdKtsgxdIjLh1tPfg",
    };
    return this.sendEvent(url, headers, eventDetails);
  }
}

// UMD Export for both browser (CDN) and NPM
if (typeof window !== "undefined") {
  window.LocationSDK = LocationSDK;
}

export default LocationSDK;
